import React from "react";
import HomeTitle from "./HomeTitle";
import { VStack, HStack, Flex, Text, Box, Tag } from "@chakra-ui/react";
import PlanBox from "../PlanBox";
import { useRouter } from "next/router";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { FcIdea } from "react-icons/fc";
import { useTranslation } from "next-i18next";
import MonthlyServicesCard from "components/MonthlyServicesCard";
import appStore from "store";
import AirportServicesCard from "./AirportServicesCard";
const CarRecommendation = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";
  return (
    isTw && (
      <Flex
        cursor="pointer"
        _hover={{ bgColor: "#E4E4E4" }}
        onClick={() =>
          router.push({
            pathname: "/blog/379",
            query: router.query,
          })
        }
        mx="auto"
        maxW="320px"
        pl="10px"
        pr="10px"
        py="20px"
        border="1px solid #333"
        w="100%"
        rounded="10px"
        bgColor="#F4F4F4"
        align="center"
      >
        <Box mr="10px">
          <FcIdea size="30px" />
        </Box>
        <Box flex="1 1 auto">
          <Text fontWeight="bold" fontSize="18px">
            {t("selectGuideTitle")}
          </Text>
          <Text>{t("selectGuideContent")}</Text>
        </Box>
        <Box flex="0 0 20px">
          <ChevronRightIcon fontSize="25px" />
        </Box>
      </Flex>
    )
  );
};
const HomeCarSelect = ({ carsData }) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  const { isShowColorChoose } = appStore;
  return (
    <VStack spacing="25px" pb="50px" px="25px" id="models" pt="55px" mt="-25px">
      <VStack mx="auto">
        <HomeTitle>{t("selectCar")}</HomeTitle>
        {/* <Text
          textAlign="center"
          fontWeight="400"
          fontSize="15px"
          letterSpacing="0.05em"
          mt={{ base: "15px", md: "0" }}
        >
          Model 3、Model X 可搭載全自動駕駛 (FSD) <br />
        </Text> */}
      </VStack>
      <CarRecommendation />
      <Flex
        w="100%"
        justifyContent="center"
        flexDirection={{ base: "column", md: "row" }}
        flexWrap="wrap"
      >
        {Object.keys(carsData).map((model) => {
          const modelData = carsData[model];
          // 取第一個當代表，因為目前沒有紀錄 Model 層級的通用資訊
          let firstData = {};
          // 取出所有不重複顏色
          let allColors = [];
          Object.keys(modelData).forEach((v, i) => {
            if (i === 0) firstData = modelData[v];
            Object.keys(modelData[v].cars).forEach((color) => {
              if (allColors.indexOf(color) === -1) allColors.push(color);
            });
          });

          return (
            <PlanBox
              key={model}
              title={model
                .replace("Model", "Model ")
                .replace("IONIQ", "IONIQ ")
                .replace("(", " (")}
              description={t(
                `${firstData.model_name.replace(/\s+/g, "")}HomeCardDesc`,
                {
                  defaultValue: "",
                }
              )}
              colors={isShowColorChoose ? allColors : []}
              buttonText={t("HomeCarCardCTA")}
              pathname="/station"
              query={{
                ...router.query,
                model,
              }}
              mr="20px"
              iconSrc={firstData.image}
              price={firstData.displayPrice.price}
              price_rt={`${t("originalPrice")} ${
                firstData.displayPrice.origin_price
              } ${t("TWD")}`}
              price_rb={t("priceRb")}
              imageH={
                firstData.model_name.includes("Model 3")
                  ? "100px"
                  : firstData.model_name.includes("Model Y")
                    ? "100px"
                    : false
              }
              imagePosRight={
                firstData.model_name.includes("Model 3")
                  ? "-105px"
                  : firstData.model_name.includes("Model Y")
                    ? "-100px"
                    : false
              }
              imagePosBottom={
                firstData.model_name.includes("Model 3")
                  ? "7px"
                  : firstData.model_name.includes("Model Y")
                    ? "10px"
                    : false
              }
              w={{ base: "100%", md: "40%" }}
              maxW={{ base: "100%", md: "400px" }}
            />
          );
        })}
        <AirportServicesCard />
        <MonthlyServicesCard />
      </Flex>
    </VStack>
  );
};
export default HomeCarSelect;
