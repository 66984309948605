import React from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  keyframes,
  usePrefersReducedMotion,
  VStack,
} from "@chakra-ui/react";
import MainButton from "../MainButton";
import Link from "next/link";

const moveup = keyframes`
  0% { transform: translateY(0%); }
  100% { transform: translateY(-50%); }
`;

const AnimationContent = () => {
  const feedbacks = [
    {
      name: "郭先生",
      text: "送車小哥人很細心，第一次開特斯拉完全不擔心，很棒的租車體驗",
    },
    {
      name: "闕小姐",
      text: "我ㄉ天啊，特斯拉加速也太快了吧 @@",
    },
    {
      name: "王先生",
      text: "車子很新, 整潔乾淨、輔助駕駛超讚 長途旅程好輕鬆，下次還會再租！",
    },
    {
      name: "陳小姐",
      text: "資訊很透明服務品質很好,很抱歉問了客服很多租車問題，很有耐心><",
    },
    {
      name: "黃小姐",
      text: "想買一台特斯拉，Model X 真的網路上其他都找不到可以短租...",
    },
    {
      name: "黃先生",
      text: "想買特斯拉搶先體驗租車試駕看看，能夠短租真的很方便且到哪都有 USPACE 可停車！",
    },
    {
      name: "郭小姐",
      text: "這次替展覽外媒來賓租車，好在方案彈性還能專人送到府！",
    },
  ];

  // 為了讓動畫前後接起，複製成兩份
  const aimaFeedbacks = [...feedbacks, ...feedbacks];

  const preferReducedMotion = usePrefersReducedMotion();
  const animation = preferReducedMotion
    ? undefined
    : `${moveup} 8s infinite linear`;

  return (
    <Box
      position="relative"
      overflow="hidden"
      maxW={{ base: "100%", md: "700px" }}
      h={{ base: "auto", md: "440px" }}
    >
      <Box
        opacity={{ base: "0", md: "1" }}
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        zIndex="10"
        bg="linear-gradient(to bottom, #000 3%, transparent 15% 85%, #000 97%)"
      />
      <Flex
        direction="column"
        position={{ base: "relative", md: "absolute" }}
        top="0"
        left="0"
        animation={{ base: "none", md: `${animation}` }}
      >
        {aimaFeedbacks.map((feedback, i) => (
          <Flex
            display={{
              base: i >= aimaFeedbacks.length / 2 && "none",
              md: "flex",
            }}
            key={i}
            align="center"
            justify="flex-start"
            p="10px 15px"
            my="10px"
            bg="#FFF"
            borderRadius="10px"
            color="#000"
          >
            <Text
              flex="0 0 60px"
              mx={{ base: "0px", md: "20px" }}
              fontWeight="700"
              fontSize="14px"
              color="#6C80F8"
            >
              {feedback.name}
            </Text>
            <Text flex="1 1 auto" fontWeight="400" fontSize="16px">
              {feedback.text}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

const HomeFeedback = () => {
  return (
    <Box
      my={{ base: "15px", md: "30px" }}
      mr={{ base: "15px", md: "auto" }}
      p={{ base: "0px 15px 0px 0px", md: "30px" }}
      w="100%"
    >
      <Box
        maxW="1064px"
        m="auto"
        p={{ base: "50px 10px", md: "50px 70px" }}
        bgImage={`url('${process.env.NEXT_PUBLIC_GCP_IMAGE_URL}/images/home/feedback_bg.jpg')`}
        bgRepeat="no-repeat"
        bgPosition={{ base: "right", md: "center" }}
        bgSize="cover"
        borderRadius={{ base: "0 28px 28px 0", md: "28px" }}
      >
        <Box color="#FFF">
          <Heading
            fontSize={{ base: "24px", md: "32px" }}
            fontWeight="300"
            letterSpacing="0.05em"
            mb="0px"
          >
            客戶高度回流，好評不斷
          </Heading>
          <Text
            fontSize="15px"
            fontWeight="400"
            letterSpacing="0.05em"
            lineHeight="50px"
            mb="20px"
          >
            聽聽忠實用戶，體驗特斯拉租車的真實心得
          </Text>
          <AnimationContent />
        </Box>
      </Box>
    </Box>
  );
};

export default HomeFeedback;
