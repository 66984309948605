import { Box, Image, Link } from "@chakra-ui/react";
import { useWindowDimensions } from "lib/windows";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { sendAppierEvent } from "lib/appier";

const HomeSwiper = ({ largeImgList, smallImgList }) => {
  const router = useRouter();
  const [imgList, setImgList] = useState([]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 992) {
      setImgList(largeImgList);
    } else {
      setImgList(smallImgList);
    }
  }, [largeImgList, smallImgList, width]);

  useEffect(() => {
    if (imgList.length > 0 && !router.asPath.includes("#homehero")) {
      if (router.query?.utm_source === "google") {
        router.push({
          hash: "homehero",
        });
      }
    }
  }, [imgList, router]);

  return (
    <Box
      id="homehero"
      align="center"
      mx={{
        base: "20px",
        md: "5%",
        lg: "calc((100% - 912px) / 2)",
        xl: "calc((100% - 960px) / 2)",
      }}
      pt={{ base: "0px", md: "40px" }}
    >
      <Swiper
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <Box>
          {imgList &&
            !!imgList.length &&
            imgList.map((item) => {
              return (
                <SwiperSlide
                  key={width > 992 ? item.banner.large : item.banner.small}
                >
                  <Link
                    href={item.link}
                    onClick={() => {
                      sendAppierEvent("banner_clicked", {
                        banner_id: `HomeSwiper_${item.title}`,
                        banner_name: item.tite,
                        redirect_url: item.link,
                      })
                    }}
                  >
                    <Image
                      rounded="16px"
                      w={width}
                      src={width > 992 ? item.banner.large : item.banner.small}
                      alt="image"
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
        </Box>
      </Swiper>
    </Box>
  );
};

export default HomeSwiper;
