import { Text, VStack } from "@chakra-ui/react";
import GCPImage from "components/GCPImage";
import { useWindowDimensions } from "lib/windows";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

const HomePartnerAndMedia = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation("common");
  const [partnerImg, setPartnerImg] = useState("");
  const [mediaImg, setMediaImg] = useState("");

  useEffect(() => {
    if (width > 390) {
      setPartnerImg("/images/corp1440_v2.png");
      setMediaImg("/images/media1440.jpg");
    } else {
      setPartnerImg("/images/corp390_v2.png");
      setMediaImg("/images/media390.jpg");
    }
  }, [width]);

  return (
    <VStack flex="1 1 50%" py="40px" pb="40px" px="20px" spacing="20px">
      <Text
        fontSize={{ base: "24px", md: "32px" }}
        fontWeight="400"
        color="#1A202C"
        lineHeight={{ base: "32px", md: "40px" }}
      >
        {t("Footer_title2_partner")}
      </Text>
      <GCPImage
        py="40px"
        w={{ base: "280px", md: "750px" }}
        src={partnerImg}
        alt="img"
      />
      <Text
        pt="40px"
        fontSize={{ base: "24px", md: "32px" }}
        fontWeight="400"
        color="#1A202C"
        lineHeight={{ base: "32px", md: "40px" }}
      >
        {t("Footer_btn203_mediaReport")}
      </Text>
      <GCPImage
        py="40px"
        w={{ base: "280px", md: "780px" }}
        src={mediaImg}
        alt="img"
      />
    </VStack>
  );
};
export default HomePartnerAndMedia;
