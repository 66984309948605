import { Box, Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import GCPImage from "./GCPImage";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { useSession } from "hooks/useSession";
import { isAfter } from "date-fns";

const NewYearBanner = () => {
  const router = useRouter();
  const { locale } = router;
  const { isLoading } = useSession();
  const isTw = locale === "zh-TW";

  const isMdUp = useBreakpointValue({ base: false, md: true });

  const isAfter1225 = useMemo(() => {
    const date = new Date();
    return isAfter(date, new Date("2024-12-24 15:59:59"));
  }, []);

  const imgPath = useMemo(() => {
    if (!router.isReady || isMdUp === undefined || isMdUp === null || isLoading)
      return "";

    return router.pathname.includes("/station")
      ? isTw
        ? `https://storage.googleapis.com/udrive-web/images/lnyVersion/2025/lnyRentBanner${isAfter1225 ? "_1225" : ""}.png`
        : `https://storage.googleapis.com/udrive-web/images/lnyVersion/2025/lnyRentBannerEN${isAfter1225 ? "_1225" : ""}.png`
      : isMdUp
        ? isTw
          ? `https://storage.googleapis.com/udrive-web/images/lnyVersion/2025/lnyBanner912${isAfter1225 ? "_1225" : ""}.png`
          : `https://storage.googleapis.com/udrive-web/images/lnyVersion/2025/lnyBanner912EN${isAfter1225 ? "_1225" : ""}.png`
        : isTw
          ? `https://storage.googleapis.com/udrive-web/images/lnyVersion/2025/lnyRentBanner${isAfter1225 ? "_1225" : ""}.png`
          : `https://storage.googleapis.com/udrive-web/images/lnyVersion/2025/lnyRentBannerEN${isAfter1225 ? "_1225" : ""}.png`;
  }, [isMdUp, isTw, router.isReady, router.pathname, isLoading]);

  if (!router.isReady || isLoading) {
    return <Box></Box>;
  }
  return (
    <Box w="100%">
      {imgPath && (
        <Image
          w="100%"
          src={`${imgPath}?v=${new Date().getTime()}`}
          alt="new-year-banner"
        />
      )}
    </Box>
  );
};
export default NewYearBanner;
