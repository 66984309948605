import React, { useState, useEffect } from "react";
import { Flex, Text, VStack, Box, Image } from "@chakra-ui/react";
import HomeTitle from "./HomeTitle";
import HomeCard from "./HomeCard";
import ProgressBar from "./ProgressBar";
import MainLink from "../MainLink";
import { DISCOUNT_RULES } from "../../lib/order";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const steps = [
  {
    title: "STEP.1",
    text: "自選時段 隨時預約",
    content: (
      <Text>
        09:00 - 20:00 指定任意時段，取還車地點自由設定。多天預約再享優惠（
        {DISCOUNT_RULES()
          .map(({ text }) => text)
          .join("/")}
        ）。特斯拉租賃最少承租一日以上；為保證客戶服務品質，暫無開放 12
        小時內即時預約。下訂完成後，車輛將準時為您送達。
      </Text>
    ),
    img: "/images/home/step1_export.png",
  },
  {
    title: "STEP.2",
    text: "訂單資訊 即時透明",
    content: (
      <Text>
        隨時透過
        <MainLink type="normal" href="/account" target="_blank">
          「我的訂單」
        </MainLink>
        掌握即時資訊，下訂之後，請
        <MainLink type="normal" href="/account" target="_blank">
          上傳身份認證
        </MainLink>
        ，我們會在您取車前認證完畢 。除了尊榮租車體驗，USPACE APP
        也提供停車服務，出門前預先保留車位，熱鬧地區也不怕沒車位！
      </Text>
    ),
    img: "/images/home/step2_export.png",
  },
  {
    title: "STEP.3",
    text: "到府送車 完整教學",
    content: (
      <Text>
        我們有專業的服務團隊，親自將車送於您的指定的取車地點，第一次體驗特斯拉也不用擔心，贈送專人深度教學，從駕駛到充電一次搞定！
      </Text>
    ),
    img: "/images/home/step3_export.png",
  },
  {
    title: "STEP.4",
    text: "還車地點 彈性自選",
    content: (
      <Text>
        <MainLink type="normal" href="#location">
          服務範圍內
        </MainLink>
        ，甲租乙還自由指定，全程無里程限制，承租每滿一天，送 300
        公里免里程費用（承租天數 * 300 為免里程費額度）。
        {/* <MainLink>點此查看其它款項細節！</MainLink> */}
      </Text>
    ),
    img: "/images/home/step4.jpg",
  },
];

const stepsEn = [
  {
    title: "STEP.1",
    text: "Select your favorite Tesla model!",
    content: (
      <Text>
        Driving a Tesla is a fantastic experience and wholly different than
        driving anything else in the automotive consumer landscape. To sample
        the revolution for yourself, you can find favorable Teslas right here on
        UDRIVE. We provide Model 3、Model 3 Long Range、Model X and Model Y,
        suitable for Travel / Business trip / Event / Test drive.
      </Text>
    ),
    img: "/images/home/step2_export.png",
  },
  {
    title: "STEP.2",
    text: "Choose Date / Time / Address in Taiwan",
    content: (
      <Text>
        Choose your pick date & return date, and we can get your car delivered
        right to you or wherever you’re going. We also offer you a discount for
        renting more days！3 Days for 5% OFF｜5 Days for
        15% OFF｜7 Days for 20% OFF｜15 Days for 35% OFF｜30 Days for 40% OFF.
      </Text>
    ),
    img: "/images/home/step1_export.png",
  },
  {
    title: "STEP.3",
    text: "Verify your identity and Pay by Credit card / Debit card",
    content: (
      <Text>
        UDRIVE accepts credit cards and debit cards with a Visa, Mastercard or
        JCB logo that are linked to a checking account. Line Pay on mobile
        devices is also accepted, but UDRIVE does not accept cash or checks as
        valid payment methods. You need to upload International Driving Permit,
        Valid Driver’s license, Passport, Resident Certificate for identity
        verification.
      </Text>
    ),
    img: "/images/home/step3_export.png",
  },
  {
    title: "STEP.4",
    text: "Your Tesla will be delivered on time!",
    content: (
      <Text>
        With so much technology packed into the stylish and sleek Tesla cars, we
        want to make sure you’re making the most of your Tesla Experience.
        Through our detailed explanation,you will fully understand all functions
        in Tesla cars. Just complete the easy booking process and enjoy your
        amazing experience！
      </Text>
    ),
    img: "/images/home/step4.jpg",
  },
];

const stepsJp = [
  {
    title: "STEP.1",
    text: "お好きなテスラモデルをお選びください",
    content: (
      <Text>
        テスラの運転は、他の自動車メーカーとは全く異なる素晴らしい体験です。
        テスラのドライビングは素晴らしい体験であり、他の自動車メーカーとは全く異なるものです。この革命を体験するために
        この革命を体験するために、UDRIVEで好評のテスラを見つけてください。
        UDRIVE。モデル3、モデル3ロングレンジ、モデルX、モデルYをご用意しています。
        旅行、出張、イベント、試乗に最適です。
      </Text>
    ),
    img: "/images/home/step2_export.png",
  },
  {
    title: "STEP.2",
    text: "台湾で日付・時間・住所を選択",
    content: (
      <Text>
        ご指定の日時にお車をお届けします。
        お車をお届けします。また、以下の割引もございます。
        レンタル日数が多いほどお得！3日間5％OFF｜5日間15％OFF
        ｜7日間20%OFF｜15日間35％OFF｜30日間40％OFF。
      </Text>
    ),
    img: "/images/home/step1_export.png",
  },
  {
    title: "STEP.3",
    text: "本人確認とクレジットカード・デビットカードによる支払い",
    content: (
      <Text>
        UDRIVEでは、VISA、Mastercard、JCBのロゴが入ったクレジットカード、デビットカードがご利用いただけます。
        JCBロゴの入ったクレジットカード、デビットカードがご利用いただけます。モバイル端末のLINE
        Pay ただし、現金や小切手はご利用になれません。
        現金や小切手はご利用いただけません。国際運転免許証のアップロードが必要です。
        有効な運転免許証、パスポート、住民票など、本人確認のための書類が必要です。
        の確認が必要です。
      </Text>
    ),
    img: "/images/home/step3_export.png",
  },
  {
    title: "STEP.4",
    text: "あなたのテスラを納期通りにお届けします",
    content: (
      <Text>
        スタイリッシュで洗練されたテスラカーには、多くの技術が詰め込まれています。
        テスラ・エクスペリエンスを最大限に活用していただきたいと思います。
        私たちの詳細な説明を通じて、テスラ車のすべての機能を完全に理解することができます。
        テスラカーに搭載されているすべての機能をご理解いただけます。簡単な予約手続きで
        素晴らしい体験をしてください
      </Text>
    ),
    img: "/images/home/step4.jpg",
  },
];

const StepBox = ({ data: step }) => {
  return (
    <VStack
      flex="1 1 100%"
      key={step.title}
      textAlign={{ base: "left", md: "center" }}
      align={{ base: "left", md: "center" }}
      mx={{ base: "21px", md: "10px" }}
      mb={{ base: "10px", md: "0" }}
    >
      <Text fontSize="16px" fontWeight="600" lineHeight="24px">
        {step.title}
      </Text>
      {step.content}
    </VStack>
  );
};

const HomeTutorial = () => {
  const router = useRouter();
  const [data, setData] = useState([]);
  const { locale } = router;
  useEffect(() => {
    setData(locale === "zh-TW" ? steps : locale === "jp" ? stepsJp : stepsEn);
  }, [router.isReady]);
  const { t } = useTranslation("common");
  return (
    <>
      <Flex
        bg="#F7F7F7"
        direction="column"
        align="center"
        py="57px"
        px={{ base: "0", md: "120px" }}
      >
        <VStack px="30px">
          <HomeTitle>
            {t("homeTutorialTitle1")}
            <br />
            {t("homeTutorialTitle2")}
          </HomeTitle>
        </VStack>
        <Flex
          w="100%"
          justify="center"
          direction={{ base: "row", md: "column" }}
          mt={{ base: "28px", md: "45px" }}
        >
          <ProgressBar />
          <Flex
            justify="space-between"
            direction={{ base: "column", md: "row" }}
            mt={{ base: "0", md: "16px" }}
          >
            {data.map((step) => (
              <VStack
                flex="1 1 100%"
                key={step.title}
                textAlign={{ base: "left", md: "center" }}
                align={{ base: "left", md: "center" }}
                mx={{ base: "21px", md: "10px" }}
                mb={{ base: "10px", md: "0" }}
              >
                <Text fontSize="16px" fontWeight="600" lineHeight="24px">
                  {step.title}
                </Text>
                <Text
                  fontSize="20px"
                  fontWeight="500"
                  lineHeight={locale === "zh-TW" ? "50px" : "30px"}
                  letterSpacing="0.05em"
                >
                  {step.text}
                </Text>
              </VStack>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Box bg="#F7F7F7" w="100%">
        <Flex
          justify="center"
          wrap="wrap"
          display={{ base: "none", md: "flex" }}
          maxW="1050px"
          mx="auto"
        >
          {data.map((step) => (
            <HomeCard
              key={step.title}
              flex="1 1 calc(50% - 40px)"
              mx="20px"
              mb="40px"
            >
              <VStack w="100%" fontSize="28px">
                <Box
                  fontSize="20px"
                  lineHeight="30px"
                  borderRadius="0 0 10px 10px"
                  px="20px"
                  bg="#EB2929"
                  color="#FFF"
                  fontWeight="600"
                >
                  {step.title}
                </Box>
                <Text fontWeight="500" px="20px">
                  {step.text}
                </Text>
                <Box
                  w="calc(100% - 2px)"
                  left="1px"
                  h="250px"
                  bgImage={`${process.env.NEXT_PUBLIC_GCP_IMAGE_URL}${step.img}`}
                  bgRepeat="no-repeat"
                  bgPosition="center"
                  bgSize="cover"
                />
                <Box
                  px="40px"
                  pb="20px"
                  fontSize="15px"
                  lineHeight="1.5"
                  pt="15px"
                >
                  {step.content}
                </Box>
              </VStack>
            </HomeCard>
          ))}
        </Flex>
      </Box>
      <VStack bg="#F7F7F7" display={{ base: "flex", md: "none" }}>
        {data.map((step) => (
          <VStack
            key={step.title}
            w="100%"
            mb="30px"
            fontSize="20px"
            lineHeight="30px"
          >
            <Box
              w="100%"
              h="170px"
              bgImage={`${process.env.NEXT_PUBLIC_GCP_IMAGE_URL}${step.img}`}
              bgRepeat="no-repeat"
              bgPosition="center"
              bgSize="cover"
              position="relative"
              overflow="hidden"
            >
              <Box
                transform="skew(-15deg)"
                w="max-content"
                px="20px"
                bg="#EB2929"
                color="#FFF"
                fontWeight="600"
                position="absolute"
                bottom="0"
                right="-5px"
              >
                {step.title}
              </Box>
            </Box>
            <Text fontWeight="300" py="10px">
              {step.text}
            </Text>
            <Box px="25px" pb="30px" fontSize="16px" lineHeight="1.7">
              {step.content}
            </Box>
          </VStack>
        ))}
      </VStack>
    </>
  );
};

export default HomeTutorial;
