import {
  Box,
  Flex,
  Image,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import GCPImage from "./GCPImage";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

const SummerBanner = () => {
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";

  const [isClient, setIsClient] = useState(false);
  const isMdUp = useBreakpointValue({ base: false, md: true });

  useEffect(() => {
    setIsClient(true);
  }, []);

  const imgPath = useMemo(() => {
    if (isMdUp === undefined || isMdUp === null) {
      return "";
    }
    return isMdUp
      ? isTw
        ? "https://storage.googleapis.com/udrive-web/images/summer/summerWebBanner.png"
        : "https://storage.googleapis.com/udrive-web/images/summer/summerWebEnBanner.png"
      : isTw
      ? "https://storage.googleapis.com/udrive-web/images/summer/summerMobileBanner.png"
      : "https://storage.googleapis.com/udrive-web/images/summer/summerMobileEnBanner.png";
  }, [isMdUp]);

  if (!isClient) {
    return (
      <Flex
        align="center"
        justify="center"
        minH={{ base: "148px", md: "141px" }}
        w="100%"
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box w="100%">
      <Image
        minH={{ base: "148px", md: "141px" }}
        borderRadius={{ base: "0px", md: "8px" }}
        w="100%"
        src={imgPath}
        alt="new-year-banner"
      />
    </Box>
  );
};
export default SummerBanner;
