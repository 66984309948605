import { useState, useEffect } from "react";
import HomeTitle from "./HomeTitle";
import { VStack, Flex, Text, Box, AspectRatio, Image } from "@chakra-ui/react";
import api from "../../store/api";
import { useRouter } from "next/router";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useTranslation } from "next-i18next";
import { BLOG_EN, BLOG_JP, FILTERED_BLOG_ID } from "../../constants/blog";

const HomeBlog = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  useEffect(() => {
    api.getPosts({ page: page }).then((resp) => {
      if (resp.data.data.length === 0) {
        return;
      }
      const countPostNum = resp.data.data
        .filter(({ thumbnail, ID }) => thumbnail && ID !== 379 && ID !== 693)
        .filter((post) => {
          return locale === "zh-TW"
            ? !BLOG_EN.includes(Number(post.ID)) &&
                !FILTERED_BLOG_ID.includes(Number(post.ID)) &&
                !BLOG_JP.includes(Number(post.ID))
            : locale === "en"
            ? BLOG_EN.includes(Number(post.ID))
            : BLOG_JP.includes(Number(post.ID));
        });
      if (countPostNum == 0) {
        setPage((state) => state + 1);
      } else {
        setPosts((state) =>
          state.concat(
            resp.data.data
              .filter(
                ({ thumbnail, ID }) => thumbnail && ID !== 379 && ID !== 693
              )
              .filter((post) => {
                return locale === "zh-TW"
                  ? !BLOG_EN.includes(Number(post.ID)) &&
                      !FILTERED_BLOG_ID.includes(Number(post.ID)) &&
                      !BLOG_JP.includes(Number(post.ID))
                  : locale === "en"
                  ? BLOG_EN.includes(Number(post.ID))
                  : BLOG_JP.includes(Number(post.ID));
              })
          )
        );
        // 計算文章數量，如果小於10則繼續載入下一頁
        const postsNum = posts.concat(
          resp.data.data
            .filter(
              ({ thumbnail, ID }) => thumbnail && ID !== 379 && ID !== 693
            )
            .filter((post) => {
              return locale === "zh-TW"
                ? !BLOG_EN.includes(Number(post.ID)) &&
                    !FILTERED_BLOG_ID.includes(Number(post.ID)) &&
                    !BLOG_JP.includes(Number(post.ID))
                : locale === "en"
                ? BLOG_EN.includes(Number(post.ID))
                : BLOG_JP.includes(Number(post.ID));
            })
        );
        if (postsNum.length < 10) {
          setPage((state) => state + 1);
        }

        setLastPage(resp.data.meta.last_page);
      }
    });
  }, [locale, page]);

  return (
    <VStack spacing="25px" pb="70px" w="100%" id="news" pt="10px" mt="-15px">
      <VStack px="30px" w="100%">
        <HomeTitle>{t("newPost", { defaultValue: "" })}</HomeTitle>
        <Text
          textAlign="center"
          fontWeight="400"
          fontSize="15px"
          lineHeight="1.8"
          letterSpacing="0.05em"
          mt={{ base: "15px", md: "0" }}
        >
          {t("newPostInfo")}
        </Text>
      </VStack>
      <Flex w="100%" justifyContent="start" px="30px" overflow="auto">
        {posts
          .filter((post) => {
            return locale === "zh-TW"
              ? !BLOG_EN.includes(Number(post.ID)) &&
                  !FILTERED_BLOG_ID.includes(Number(post.ID)) &&
                  !BLOG_JP.includes(Number(post.ID))
              : locale === "en"
              ? BLOG_EN.includes(Number(post.ID))
              : BLOG_JP.includes(Number(post.ID));
          })
          .map((p) => {
            return (
              <Box
                key={p.ID}
                cursor="pointer"
                w="auto"
                flex={{
                  base: "0 0 calc(100% - 100px)",
                  md: "0 0 300px",
                }}
                mr="20px"
                onClick={() => {
                  delete router.query["post_id_name"];
                  router.push({
                    pathname: `/blog/${p.ID}-${p.post_name}`,
                    query: router.query,
                  });
                }}
              >
                <Box
                  h="100%"
                  pos="relative"
                  overflow="hidden"
                  borderRadius="10px"
                  transform="translateZ(0)"
                >
                  <AspectRatio top="0" ratio={{ base: 16 / 9 }} w="100%">
                    <Image src={p.thumbnail} />
                  </AspectRatio>
                  <Box
                    h="100%"
                    w="100%"
                    bottom="0"
                    px="20px"
                    py="14px"
                    bgColor="#000"
                    minH="65px"
                    color="#fff"
                    fontWeight="800"
                    fontSize={{ base: "14px", md: "16px" }}
                  >
                    <Text lineHeight="1.3">{p.post_title}</Text>
                  </Box>
                </Box>
              </Box>
            );
          })}
        {page < lastPage && (
          <Box
            ml="10px"
            justifyContent="center"
            cursor="pointer"
            alignSelf="center"
            pos="relative"
            onClick={() => {
              setPage((state) => state + 1);
            }}
          >
            <MdOutlineKeyboardArrowRight fontSize="36px" />
            <Text
              pos="absolute"
              left="-10px"
              top="40px"
              w="80px"
              fontSize="12px"
            >
              {t("seeMore")}
            </Text>
          </Box>
        )}
      </Flex>
    </VStack>
  );
};

export default HomeBlog;
