import HomePartnerAndMedia from "components/Home/HomePartnerAndMedia";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import HomeBlog from "../components/Home/HomeBlog";
import HomeCarSelect from "../components/Home/HomeCarSelect";
import HomeCompare from "../components/Home/HomeCompare";
import HomeFAQ from "../components/Home/HomeFAQ";
import HomeFeedback from "../components/Home/HomeFeedback";
import HomeHero from "../components/Home/HomeHero/HomeHero";
import HomeTutorial from "../components/Home/HomeTutorial";
import MainFooter from "../components/Layout/MainFooter";
import { reduceCarsToOneLevel, reduceCarsToTwoLevel } from "../lib/cars";
import api from "../store/api";
import { useSession } from "hooks/useSession";
import HomeSwiper from "components/Home/HomeHero/HomeSwiper";

function Home({
  cars = [],
  largeImgList = [],
  smallImgList = [],
  ownerSettings = [],
  deliveryZones = [],
}) {
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";
  const carsData = reduceCarsToTwoLevel(cars);
  const orgCarsData = reduceCarsToOneLevel(cars);

  return (
    <div>
      <HomeHero
        largeImgList={largeImgList}
        smallImgList={smallImgList}
        ownerSettings={ownerSettings}
        deliveryZones={deliveryZones}
      />
      <HomeSwiper largeImgList={largeImgList} smallImgList={smallImgList} />
      <HomeCarSelect carsData={orgCarsData} />
      <HomeBlog />
      <HomeTutorial />
      <HomeFAQ />
      {isTw && <HomeCompare carsData={carsData} />}
      {isTw && <HomeFeedback />}
      <HomePartnerAndMedia />
      {/* {isTestTeam === false && <Calltoaction href={"#models"} />} */}
      <HomeCarSelect carsData={orgCarsData} />
      <MainFooter />
    </div>
  );
}

export async function getServerSideProps(context) {
  const { locale } = context;

  // Fetch Home Page Cars Data

  const carsResp = await api.getCars();
  const cars = carsResp?.data?.data || [];

  const ownerSettingsResp = await api.getAllOwnerSetting();
  const ownerSettings = ownerSettingsResp.data.data;
  const deliveryZonesResp = await api.getDeliveryZone();
  const deliveryZones = deliveryZonesResp.data.data;

  const bannerResp = await api.getBannerList(locale);
  const largeImgList = bannerResp.data.filter((item) => {
    if (item?.banner.large) {
      return true;
    }
  });
  const smallImgList = bannerResp.data.filter((item) => {
    if (item?.banner.small) {
      return true;
    }
  });

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      cars,
      largeImgList,
      smallImgList,
      ownerSettings,
      deliveryZones,
    },
  };
}

export default Home;
