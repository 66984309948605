import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Flex, Tag } from "@chakra-ui/react";

const ProgressBar = () => {
  return (
    <Flex
      justify="space-between"
      direction={{ base: "column", md: "row" }}
      mt={{ base: "0%", md: "0" }}
    >
      <Flex
        align="center"
        justify="center"
        flex="1 1 100%"
        position="relative"
        zIndex="1"
        _after={{
          content: `""`,
          position: "absolute",
          w: { base: "2px", md: "50%" },
          h: { base: "calc(50% - 11px)", md: "2px" },
          bg: "#515151",
          top: { base: "calc(50% + 11px)", md: "calc(50% - 1px)" },
          left: { base: "calc(50% - 1px)", md: "calc(50% + 11px)" },
        }}
      >
        <Tag
          h="22px"
          w="22px"
          bg="#515151"
          p="5px"
          border="1px solid #515151"
          borderRadius="50%"
        >
          <FaMapMarkerAlt color="#FFF" fontSize="12px" />
        </Tag>
      </Flex>
      <Flex flex="1 1 100%" align="center" justify="center" zIndex="1">
        <Tag
          h="22px"
          w="22px"
          bg="#E5E5E5"
          border="1px solid #C4C4C4"
          borderRadius="50%"
        />
      </Flex>
      <Flex flex="1 1 100%" align="center" justify="center" zIndex="1">
        <Tag
          h="22px"
          w="22px"
          bg="#E5E5E5"
          border="1px solid #C4C4C4"
          borderRadius="50%"
        />
      </Flex>
      <Flex
        flex="1 1 100%"
        align="center"
        justify="center"
        position="relative"
        _after={{
          content: `""`,
          position: "absolute",
          w: { base: "2px", md: "300%" },
          h: { base: "300%", md: "2px" },
          bg: "#C4C4C4",
          bottom: { base: "calc(50% + 11px)", md: "calc(50% - 1px)" },
          right: { base: "calc(50% - 1px)", md: "calc(50% + 11px)" },
        }}
      >
        <Tag
          h="22px"
          w="22px"
          bg="#E5E5E5"
          border="1px solid #C4C4C4"
          borderRadius="50%"
        />
      </Flex>
    </Flex>
  );
};

export default ProgressBar;
