import { Heading, Flex } from "@chakra-ui/react";

function HomeTitle(props) {
  return (
    <Flex direction="column" align="center">
      <Heading
        textAlign="center"
        fontWeight="300"
        fontSize={{ base: "24px", md: "32px" }}
        lineHeight={{ base: "38px", md: "50px" }}
        letterSpacing="0.05em"
        {...props}
        >
        {props.children}
      </Heading>
    </Flex>
  );
}

export default HomeTitle;
