import { Flex, Box, useStyleConfig } from "@chakra-ui/react";

function HomeCard(props) {
  return (
    <Flex pos="relative" flex="1 1 auto" {...props} >
      <Box
        w="100%"
        h="100%"
        backgroundColor="#F5F5F5"
        position="absolute"
        borderRadius="16px"
        zIndex="1"
        top="5px"
        left="5px"
      ></Box>
      <Box
        w="100%"
        h="100%"
        position="absolute"
        top="0px"
        left="0px"
        border="1px solid #000"
        borderRadius="16px"
        zIndex="2"
      ></Box>
      <Box w="100%" zIndex="3" pos="relative">
        {props.children}
      </Box>
    </Flex>
  );
}

export default HomeCard;
