import React, { useState } from "react";
import Link from "next/link";
import { Box, Flex, Text, HStack } from "@chakra-ui/react";
import HomeCard from "./Home/HomeCard";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import GCPImage from "./GCPImage";
const MonthlyServicesCard = (props) => {
  const { t } = useTranslation("common");
  const [isHover, hover] = useState(false);
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";
  return (
    <HomeCard
      w={{ base: "100%", md: "40%" }}
      maxW={{ base: "100%", md: "400px" }}
      cursor="pointer"
      mb="25px"
      mx={{ base: "0px", md: "10px" }}
      {...props}
    >
      <Flex
        borderRadius="16px"
        flexDir="column"
        align="start"
        w="100%"
        pos="relative"
        overflow="hidden"
        p="20px"
        py="15px"
        color="#000"
        textAlign="left"
        onMouseEnter={() => hover(true)}
        onMouseLeave={() => hover(false)}
        transition="0.3s ease"
        minH={{ base: "300px", md: "280px" }}
        h="100%"
        onClick={() => {
          router.push({
            pathname: locale === "zh-TW" ? "/blog/612" : "/blog/2211",
            query: {
              ...router.query,
            },
          });
        }}
      >
        <GCPImage
          zIndex="-1"
          pos="absolute"
          right={"-30px"}
          bottom={"-30px"}
          alt="udrive"
          transform={{
            base: ["translate(10%, -20%)"],
            md: "translateY(-20%)",
          }}
          transition="0.3s ease"
          w={{ base: "275px", md: "300px" }}
          src={"/images/home/EnterpriseSolution.png"}
        />
        <Text fontSize="25px" fontWeight="bolder">
          {t("MonthlyPlanTitle")}
        </Text>
        <Box
          letterSpacing="auto"
          fontSize="18px"
          pb="15px"
          mb="15px"
          flex="1 1 auto"
        >
          <Text minH="50px" opacity="0.8">
            {t("MonthlyPlanContent")}
          </Text>
          <Text fontSize="14px" lineHeight="20px" color="#748598" mt="4px">
            {t("MonthlyPlanSubTitle")}
          </Text>
          <HStack mt="10px">
            {/* {colors.map((c) => (
                  <Box w="22px" h="22px" key={c}>
                    <GCPImage src={COLORS[c]} alt={c} />
                  </Box>
                ))} */}
          </HStack>
        </Box>
        <Flex alignItems="center" mt="10px" zIndex="1" pos="relative" w="100%">
          <Text fontSize="40px" fontWeight="bolder" lineHeight={1} mr="5px">
            {/* <Text as="span">{price}</Text> */}
            {/* <Text as="span" fontSize="16px">
                  /{t("days")}
                </Text> */}
          </Text>
          {/* <Box ml="5px" flex="1 0 100px" w="100px">
                <Text fontSize="13px" textDecoration="line-through">
                  {price_rt}
                </Text>
                <Text fontSize="13px">{price_rb}</Text>
              </Box> */}
        </Flex>
        <Box
          px="30px"
          py="8px"
          ml="-5px"
          fontSize="18px"
          borderRadius="30px"
          fontWeight="bold"
          color="#fff"
          bgColor="#475aff"
          border="none"
          display="inline-block"
          mt="30px"
          opacity={isHover ? 1 : 0.8}
          transition="0.3s ease"
        >
          {t("MonthlyPlanCTA")}
        </Box>
      </Flex>
    </HomeCard>
  );
};

export default MonthlyServicesCard;
