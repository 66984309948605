import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { useSession } from "hooks/useSession";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useState } from "react";
import api from "store/api";
import HomeCard from "./HomeCard";
import GCPImage from "components/GCPImage";
const AirportServicesCard = (props) => {
  const { t } = useTranslation("common");
  const session = useSession();
  const [isHover, hover] = useState(false);
  const router = useRouter();
  const { locale } = router;
  const isTw = locale === "zh-TW";

  const goUgo = async () => {
    let link =
      "https://go.uspace.city/home?utm_source=udrive&utm_medium=card&utm_campaign=UD首頁曝光";
    if (session.token) {
      api.getOneTimeToken().then((resp) => {
        if (resp?.data?.token) {
          link = `https://go.uspace.city/home?utm_source=udrive&utm_medium=card&utm_campaign=UD首頁曝光&token=${resp.data.token}`;
        }
        window.open(link, "_blank");
      });
    } else {
      window.open(link, "_blank");
    }
  };

  return (
    <HomeCard
      w={{ base: "100%", md: "40%" }}
      maxW={{ base: "100%", md: "400px" }}
      cursor="pointer"
      mb="25px"
      mx={{ base: "0px", md: "10px" }}
      {...props}
    >
      <Box cursor="pointer" onClick={goUgo}>
        <a
          style={{ display: "block", position: "relative", height: "100%" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Flex
            flexDir="column"
            align="start"
            w="100%"
            pos="relative"
            overflow="hidden"
            p="20px"
            py="15px"
            color="#000"
            textAlign="left"
            onMouseEnter={() => hover(true)}
            onMouseLeave={() => hover(false)}
            transition="0.3s ease"
            minH={{ base: "300px", md: "280px" }}
            h="100%"
          >
            <GCPImage
              pos="absolute"
              right={"-30px"}
              bottom={"-30px"}
              alt="udrive"
              transform={"translateX(0%)"}
              transition="0.3s ease"
              h={"250px"}
              src={"/images/home/air_w.png"}
            />
            <Text fontSize="25px" fontWeight="bolder">
              {t("airport.title")}
            </Text>
            <Box
              letterSpacing="auto"
              fontSize="18px"
              pb="15px"
              mb="15px"
              flex="1 1 auto"
            >
              <Text minH="50px" opacity="0.8">
                {t("airport.desc")}
              </Text>
              <HStack mt="10px">
                {/* {colors.map((c) => (
                  <Box w="22px" h="22px" key={c}>
                    <GCPImage src={COLORS[c]} alt={c} />
                  </Box>
                ))} */}
              </HStack>
            </Box>
            <Flex
              alignItems="center"
              mt="10px"
              zIndex="1"
              pos="relative"
              w="100%"
            >
              <Text fontSize="40px" fontWeight="bolder" lineHeight={1} mr="5px">
                {/* <Text as="span">{price}</Text> */}
                {/* <Text as="span" fontSize="16px">
                  /{t("days")}
                </Text> */}
              </Text>
              {/* <Box ml="5px" flex="1 0 100px" w="100px">
                <Text fontSize="13px" textDecoration="line-through">
                  {price_rt}
                </Text>
                <Text fontSize="13px">{price_rb}</Text>
              </Box> */}
            </Flex>
            <Box
              px="30px"
              py="8px"
              ml="-5px"
              fontSize="18px"
              borderRadius="30px"
              fontWeight="bold"
              color="#fff"
              bgColor="#475aff"
              border="none"
              display="inline-block"
              mt="30px"
              opacity={isHover ? 1 : 0.8}
              transition="0.3s ease"
            >
              {t("airport.btn")}
            </Box>
          </Flex>
        </a>
      </Box>
    </HomeCard>
  );
};

export default AirportServicesCard;
