import { Link } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";

const MainLink = ({ type, children, href, target, ...props }) => {
  return (
    <Box
      as="span"
      {...props}
      color={type === "normal" ? "#333" : "#296195"}
      fontWeight={type === "normal" ? "" : "bold"}
      textDecoration={type === "normal" ? "underline" : ""}
    >
      <Link href={href} target={target}>
        {children}
      </Link>
    </Box>
  );
};

export default MainLink;
