import React from "react";
import { Text, VStack, Flex, Box, Link, HStack } from "@chakra-ui/react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import GCPImage from "components/GCPImage";

const Title = ({ children }) => {
  return (
    <Text
      fontSize="18px"
      fontWeight="700"
      lineHeight="24px"
      pt={{ base: "40px", md: "0px" }}
    >
      {children}
    </Text>
  );
};
const SubTitle = ({ children, onClick }) => {
  return (
    <Text
      cursor="pointer"
      fontSize="16px"
      fontWeight="400"
      lineHeight="24px"
      my={{ base: "8px", md: "16px" }}
      onClick={onClick}
    >
      {children}
    </Text>
  );
};

const MainFooter = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;
  const logo = locale === "zh-TW" ? "logo_v_zh-TW.svg" : "logo_v.svg";
  return (
    <Box
      w="100%"
      px={{ base: "25px", md: "0" }}
      py="50px"
      position="relative"
      bg="#E9EAEF"
    >
      <footer>
        <VStack
          mx={{ base: "0px", md: "80px" }}
          spacing={{ base: "40px", md: "16px" }}
        >
          <HStack
            pb="40px"
            borderBottom="1px solid rgba(0, 0, 0, 0.1)"
            flexDir={{ base: "column", md: "row" }}
            align="start"
            flex="1 1 auto"
            w="100%"
          >
            <Box flex="1 1 25%" pr="3%">
              <GCPImage w="100%" src="/images/office.png" alt="logo" />
              <GCPImage
                w="100%"
                src={`/images/brand/logos/${logo}`}
                alt="logo"
              />
            </Box>
            <Box flex="1 1 25%">
              <Title>{t("Footer_title1_userSupport")}</Title>
              <SubTitle
                onClick={() => {
                  router.push({ pathname: "/about", query: router.query });
                }}
              >
                {t("Footer_btn101_aboutUdrive")}
              </SubTitle>
              <SubTitle
                // 目前沒有隱私權政策頁面，先跳到政策與保險頁面
                onClick={() => {
                  router.push({
                    pathname: "/info",
                    query: router.query,
                  });
                }}
              >
                {t("Footer_btn102_privacyPolicy")}
              </SubTitle>
              <SubTitle
                onClick={() => {
                  router.push({
                    pathname: "/",
                    query: router.query,
                    hash: "qa",
                  });
                }}
              >
                {t("Footer_btn103_FAQ")}
              </SubTitle>
              <SubTitle
                onClick={() => {
                  router.push({
                    pathname: "/info",
                    query: router.query,
                  });
                }}
              >
                {t("Footer_btn104_policyAndInsurance")}
              </SubTitle>
            </Box>
            <Box flex="1 1 25%">
              <Title>{t("Footer_title2_partner")}</Title>
              <SubTitle
                onClick={() => {
                  router.push({
                    pathname: "/blog/1227-udrive-recruiting-partners",
                    query: router.query,
                  });
                }}
              >
                {t("Footer_btn201_becomePartner")}
              </SubTitle>
              <SubTitle
                onClick={() => {
                  router.push({
                    pathname: "/partner",
                    query: router.query,
                  });
                }}
              >
                {t("Footer_btn202_cooperationPlan")}
              </SubTitle>
              {/* 目前還沒有媒體報導頁面 */}
              {/* <SubTitle>{t("Footer_btn203_mediaReport")}</SubTitle> */}
            </Box>
            <Box flex="1 1 25%">
              <Title>{t("Footer_title3_paymentMethod")}</Title>
              <Flex mt="16px">
                {/* <GCPImage src="/images/about/linepayIcon.svg" alt="logo" /> */}
                <GCPImage
                  src="/images/about/cardIcon.svg"
                  alt="logo"
                />
              </Flex>
              <Flex mt="40px">
                <Box
                  cursor="pointer"
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/udrive.city/",
                      "_blank"
                    );
                  }}
                >
                  <GCPImage src="/images/about/igIcon.svg" alt="logo" />
                </Box>
                <Box
                  ml="16px"
                  cursor="pointer"
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/udrive.city",
                      "_blank"
                    );
                  }}
                >
                  <GCPImage src="/images/about/fbIcon.svg" alt="logo" />
                </Box>
              </Flex>
            </Box>
          </HStack>

          <HStack
            w="100%"
            justify="space-between"
            flexDir={{ base: "column", md: "row" }}
            align={{ base: "start", md: "center" }}
          >
            <GCPImage w="183px" alt="logo" src="/images/brand/logo_uspace.svg" />
            <Text
              pt={{ base: "40px", md: "10px" }}
              mt="8px"
              color="#748598"
              fontSize={{ base: "12px", md: "14px" }}
              fontWeight="400"
              lineHeight={{ base: "20px", md: "22px" }}
            >
              悠勢科技股份有限公司
              <br />
              統一編號：52492792
            </Text>
            <Text
              mt="8px"
              color="#748598"
              fontSize="16px"
              fontWeight="400"
              lineHeight="24px"
              pt={{ base: "40px", md: "10px" }}
            >
              © 2022 UDrive. All rights reserved.
            </Text>
          </HStack>
        </VStack>
      </footer>
    </Box>
  );
};

const MainFooterOld = () => {
  const { t } = useTranslation("common");
  return (
    <Box
      w="100%"
      px={{ base: "25px", md: "0" }}
      py="50px"
      bgImage="url('/images/home/model3.jpg')"
      bgRepeat="no-repeat"
      bgPosition={{ base: "28%", md: "center" }}
      bgSize="cover"
      position="relative"
      pb="100px"
    >
      <Box
        w="100%"
        h="100%"
        position="absolute"
        top="0"
        left="0"
        opacity={0.7}
        bgColor="#000"
      />
      <footer>
        <VStack
          mx={{ base: "0", md: "20px" }}
          spacing={{ base: "100px", md: "45px" }}
        >
          <Flex
            w={{ base: "100%", md: "100%" }}
            h={{ base: "600px", md: "auto" }}
            direction={{ base: "column", md: "row" }}
            justify="space-around"
            zIndex="1"
          >
            <VStack
              align="left"
              spacing="20px"
              textShadow={{
                base: "none",
                md: "0 0.2em 0.4em  rgba(0,0,0, 0.4)",
              }}
            >
              <Text fontSize="18px" fontWeight="600" color="#fff">
                USPACE {t("headquarters")}
              </Text>
              <Text color="#FFF" fontSize="14px" lineHeight="28px">
                (02) 7751-8097
                <br />
                {t("companyAddress")}
              </Text>
              <Text color="#FFF" fontSize="14px" lineHeight="28px">
                悠勢科技股份有限公司
                <br />
                統一編號：52492792
              </Text>
            </VStack>
            <Box
              w="100%"
              h="1px"
              bg="#fff"
              display={{ base: "block", md: "none" }}
            />
            <VStack
              align="left"
              spacing="20px"
              textShadow={{
                base: "none",
                md: "0 0.2em 0.4em  rgba(0,0,0, 0.4)",
              }}
              mx={{ base: "0", md: "60px" }}
            >
              <Text fontSize="18px" fontWeight="600" color="#fff">
                {t("relatedLinks")}
              </Text>
              <VStack
                align="left"
                color="#fff"
                fontSize="14px"
                lineHeight="28px"
              >
                <HStack>
                  <FaFacebookF
                    fontSize="20px"
                    filter={{
                      base: "none",
                      md: "drop-shadow(0 0.2em 0.4em  rgba(0,0,0, 0.4))",
                    }}
                  />
                  <Link
                    href="https://www.facebook.com/udrive.city"
                    target="_blank"
                  >
                    Facebook
                  </Link>
                </HStack>
                <HStack>
                  <FaInstagram
                    fontSize="20px"
                    filter={{
                      base: "none",
                      md: "drop-shadow(0 0.2em 0.4em  rgba(0,0,0, 0.4))",
                    }}
                  />
                  <Link
                    href="https://www.instagram.com/udrive.city/"
                    target="_blank"
                  >
                    Instagram
                  </Link>
                </HStack>
              </VStack>
            </VStack>
            <Box
              w="100%"
              h="1px"
              bg="#fff"
              display={{ base: "block", md: "none" }}
            />
            <VStack
              align="left"
              spacing="20px"
              textShadow={{
                base: "none",
                md: "0 0.2em 0.4em  rgba(0,0,0, 0.4)",
              }}
            >
              <Text fontSize="18px" fontWeight="600" color="#fff">
                USPACE {t("team")}
              </Text>
              <Box
                w="313px"
                maxW="100%"
                h="200px"
                bgImage="url('/images/home/team_export.jpg')"
                bgRepeat="no-repeat"
                bgPosition="center"
                bgSize="cover"
                borderRadius="20px"
              />
            </VStack>
          </Flex>
          <VStack zIndex="1" color="#FFF">
            <Text
              fontSize={{ base: "18px", md: "20px" }}
              fontWeight="300"
              letterSpacing={{ base: "0.4em", md: "1.175em" }}
              lineHeight="50px"
            >
              {t("teamInfo")}
            </Text>
            <Text fontSize="16px" fontWeight="400" lineHeight="26px">
              © 2022 UDrive. All rights reserved.
            </Text>
          </VStack>
        </VStack>
      </footer>
    </Box>
  );
};

export default MainFooter;
