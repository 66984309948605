import React from "react";
import OvalTop from "./OvalTop";
import OvalButtom from "./OvalButtom";
import HomeTitle from "./HomeTitle";
import {
  Flex,
  VStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";

const HomeCompare = ({ carsData }) => {
  let lowestPrice = "3,315";
  if (carsData?.Model3 && carsData?.Model3["Tesla-Model3-"]) {
    lowestPrice = carsData?.Model3["Tesla-Model3-"]?.displayPrice?.price;
  }
  return (
    <Flex
      w="100%"
      py="50px"
      bg="black"
      bgImage={`linear-gradient(to right, transparent, #000 10% 90%, transparent), url('${process.env.NEXT_PUBLIC_GCP_IMAGE_URL}/images/home/compare_bg_left.png'), url('${process.env.NEXT_PUBLIC_GCP_IMAGE_URL}/images/home/compare_bg_right.png')`}
      bgRepeat="no-repeat"
      bgPosition="center, left, right"
      bgSize="cover, contain, contain"
      align="center"
      direction="column"
      position="relative"
      overflow="hidden"
    >
      <OvalTop />
      <VStack color="#FFF" spacing="35px" w="100%">
        <VStack spacing="0">
          <HomeTitle fontSize={{ base: "32px", md: "24px" }}>
            特斯拉租賃也可以這麼便宜
          </HomeTitle>
          <Text
            fontWeight="400"
            fontSize="15px"
            lineHeight={{ base: "36px", md: "50px" }}
            letterSpacing="0.05em"
          >
            價格直逼一般油車租車，我們依然最高規格服務
          </Text>
        </VStack>
        <TableContainer pb="30px">
          <Table variant="unstyled">
            <Thead>
              <Tr>
                <Th />
                <Th
                  fontSize="20px"
                  lineHeight="50px"
                  fontWeight="500"
                  bg="#EB2929"
                  borderRadius="22px 22px 0 0"
                >
                  悠駕 UDRIVE
                </Th>
                <Th fontSize="20px" lineHeight="50px" fontWeight="500">
                  他牌特斯拉租賃
                </Th>
                <Th fontSize="20px" lineHeight="50px" fontWeight="500">
                  一般油電車租賃
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td lineHeight="150%">價格</Td>
                <Td border="none" lineHeight="150%" bg="#EB2929">
                  <Flex w="278px" justify="space-between" align="center">
                    <Text>{lowestPrice}元起</Text>
                    <FaStar />
                  </Flex>
                </Td>
                <Td lineHeight="150%">5,280元以上</Td>
                <Td lineHeight="150%">2,500元以上</Td>
              </Tr>
              <Tr>
                <Td lineHeight="150%">押金</Td>
                <Td border="none" lineHeight="150%" bg="#EB2929">
                  <Flex w="278px" justify="space-between" align="center">
                    <Text>固定 10,000 元</Text>
                    <FaStar />
                  </Flex>
                </Td>
                <Td lineHeight="150%">依據車輛高達 100,000 以上</Td>
                <Td lineHeight="150%">10,000 元以上</Td>
              </Tr>
              <Tr>
                <Td lineHeight="150%">保險</Td>
                <Td border="none" lineHeight="150%" bg="#EB2929">
                  <Flex w="278px" justify="space-between" align="center">
                    <Text>乙式保險</Text>
                    <FaStar />
                  </Flex>
                </Td>
                <Td lineHeight="150%">僅強制險，其餘保險需另購</Td>
                <Td lineHeight="150%">保險需另購</Td>
              </Tr>
              <Tr>
                <Td lineHeight="150%">租車天數</Td>
                <Td border="none" lineHeight="150%" bg="#EB2929">
                  最低一日，天數小時任選
                </Td>
                <Td lineHeight="150%">一至三日固定天數</Td>
                <Td lineHeight="150%">最少一小時起算</Td>
              </Tr>
              {/* <Tr>
                <Td lineHeight="150%">送還車</Td>
                <Td border="none" lineHeight="150%" bg="#EB2929">
                  <Flex w="278px" justify="space-between" align="center">
                    <Text>免費到府送還車</Text>
                    <FaStar />
                  </Flex>
                </Td>
                <Td lineHeight="150%">固定定點選擇 指定地點須加價</Td>
                <Td lineHeight="150%">路邊租還 / 定點租還車</Td>
              </Tr> */}
              <Tr>
                <Td lineHeight="150%">車輛升級費用</Td>
                <Td border="none" lineHeight="150%" bg="#EB2929">
                  <Flex w="278px" justify="space-between" align="center">
                    <Text>最低</Text>
                    <FaStar />
                  </Flex>
                </Td>
                <Td lineHeight="150%">高</Td>
                <Td lineHeight="150%">--</Td>
              </Tr>
              {/* <Tr>
                <Td lineHeight="150%">充電費用</Td>
                <Td border="none" lineHeight="150%" bg="#EB2929">
                  <Flex w="278px" justify="space-between" align="center">
                    <Text>特斯拉超充站免費</Text>
                    <FaStar />
                  </Flex>
                </Td>
                <Td lineHeight="150%">使用者付費</Td>
                <Td lineHeight="150%">另附油卡 / 里程費另計</Td>
              </Tr> */}
              <Tr>
                <Td lineHeight="150%">教學服務</Td>
                <Td border="none" lineHeight="150%" bg="#EB2929">
                  <Flex w="278px" justify="space-between" align="center">
                    <Text>專人教學</Text>
                    <FaStar />
                  </Flex>
                </Td>
                <Td lineHeight="150%">專人教學</Td>
                <Td lineHeight="150%">教學手冊</Td>
              </Tr>
              <Tr>
                <Td lineHeight="150%">附加服務</Td>
                <Td
                  border="none"
                  lineHeight="150%"
                  bg="#EB2929"
                  borderRadius="0 0 22px 22px"
                >
                  <Flex w="278px" justify="space-between" align="center">
                    <Text>專用傘、瓶裝水</Text>
                    <FaStar />
                  </Flex>
                </Td>
                <Td lineHeight="150%">無</Td>
                <Td lineHeight="150%">無</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </VStack>
      <OvalButtom bg="#FFF" />
    </Flex>
  );
};

export default HomeCompare;
