import React from "react";
import { Box } from "@chakra-ui/react";

const OvalButtom = (props) => {
  return (
    <Box
      {...props}
      w="120%"
      h="20%"
      position="absolute"
      borderRadius="100% 100% 0 0"
      bottom="-15%"
      display={{ base: "none", md: "block" }}
    />
  );
};

export default OvalButtom;
